import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios'

const BASE_URL: string = 'https://flakon2.uz/api/'

const myAxios: AxiosInstance = axios.create({ baseURL: BASE_URL, timeout: 30000, headers: { 'Content-Type': 'application/json' } })

myAxios?.interceptors?.request?.use(
  (config: InternalAxiosRequestConfig) => {
    const params: URLSearchParams = new URLSearchParams(window?.location?.search)
    const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined'

    const USER_TOKEN: string =
      isFromTelegramBot && config?.url === '/admins/userme' && params?.get('telegram_bot') ? params?.get('telegram_bot') || '' : localStorage?.getItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$') || ''

    if (USER_TOKEN) config.headers['Authorization'] = USER_TOKEN
    return config
  },(error) => Promise?.reject(error)
)
myAxios?.interceptors?.response?.use((response: AxiosResponse) => response,(error: AxiosError) => Promise?.reject(error))

export default myAxios